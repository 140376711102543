import environment from '@trustblock/constants/environment.constants';
import { isValidationErrorResponse } from '@trustblock/helpers/guards';
import type {
  CreateAuditResponse,
  ExtractAuditInfoResponse,
  GetAuditPageDataResponse,
  GetAuditsSlugsResponse
} from '@trustblock/types/audit.types';
import type {
  CreateAuditorResponse,
  GetAuditorPageDataResponse,
  GetAuditorsSlugsResponse,
  GetUploadReportAuthorizationResponse,
  SearchAuditorsResponse,
  UpdateAuditorResponse
} from '@trustblock/types/auditor.types';
import { HttpMethod } from '@trustblock/types/http.types';
import type {
  CreateIntegratorResponse,
  GetIntegratorPageDataResponse,
  UpdateIntegratorResponse
} from '@trustblock/types/integrator.types';
import type {
  GetProjectPageDataResponse,
  GetProjectsSlugsResponse,
  SearchProjectsResponse
} from '@trustblock/types/project.types';
import type { GetUserResponse, UpdateUserProfilePictureResponse } from '@trustblock/types/user.types';
import type { CreateAuditParams, ExtractAuditInfoParams } from '@trustblock/validators/audit.validators';
import type {
  CreateAuditorParams,
  SearchAuditorsParams,
  UpdateAuditorParams
} from '@trustblock/validators/auditor.validators';
import type { CreateIntegratorParams, UpdateIntegratorParams } from '@trustblock/validators/integrator.validators';
import type { SearchProjectsParams } from '@trustblock/validators/project.validators';

export const apiRoutes = {
  getAuditsSlugs: () => [HttpMethod.Get, 'ui/audit', true] as const,
  getProjectsSlugs: () => [HttpMethod.Get, 'ui/project', true] as const,
  getAuditorsSlugs: () => [HttpMethod.Get, 'ui/auditor', true] as const,
  searchAuditors: () => [HttpMethod.Get, 'ui/auditor/search', true] as const,
  searchProjects: () => [HttpMethod.Get, 'ui/project/search', true] as const,
  createAuditor: () => [HttpMethod.Post, 'auditor'] as const,
  createIntegrator: () => [HttpMethod.Post, 'integrator'] as const,
  publishAudit: () => [HttpMethod.Post, 'v1/audit'] as const,
  getUser: () => [HttpMethod.Get, 'user'] as const,
  getUploadReportAuthorization: () => [HttpMethod.Get, 'v1/auditor/upload-report-authorization'] as const,
  updateUserProfilePicture: () => [HttpMethod.Post, 'user/upload/profile-picture'] as const,
  updateIntegrator: () => [HttpMethod.Patch, 'integrator'] as const,
  updateAuditor: () => [HttpMethod.Patch, 'auditor'] as const,
  getIntegratorPageData: (slug: string) => [HttpMethod.Get, `ui/integrator/${slug}`, true] as const,
  getAuditorPageData: (slug: string) => [HttpMethod.Get, `ui/auditor/${slug}`, true] as const,
  getAuditPageData: (id: string) => [HttpMethod.Get, `ui/audit/${id}`, true] as const,
  getProjectPageData: (slug: string) => [HttpMethod.Get, `ui/project/${slug}`, true] as const,
  extractAuditInfo: () => [HttpMethod.Post, 'audit/ai'] as const
} as const;

export interface ApiRoutesResponses {
  getAuditsSlugs: GetAuditsSlugsResponse;
  getProjectsSlugs: GetProjectsSlugsResponse;
  getAuditorsSlugs: GetAuditorsSlugsResponse;
  searchAuditors: SearchAuditorsResponse;
  searchProjects: SearchProjectsResponse;
  createAuditor: CreateAuditorResponse;
  createIntegrator: CreateIntegratorResponse;
  publishAudit: CreateAuditResponse;
  getUser: GetUserResponse;
  getUploadReportAuthorization: GetUploadReportAuthorizationResponse;
  updateUserProfilePicture: UpdateUserProfilePictureResponse;
  updateIntegrator: UpdateIntegratorResponse;
  updateAuditor: UpdateAuditorResponse;
  getIntegratorPageData: GetIntegratorPageDataResponse;
  getAuditorPageData: GetAuditorPageDataResponse;
  getAuditPageData: GetAuditPageDataResponse;
  getProjectPageData: GetProjectPageDataResponse;
  extractAuditInfo: ExtractAuditInfoResponse;
}

export interface ApiRoutesParams {
  getAuditsSlugs: undefined;
  getProjectsSlugs: undefined;
  getAuditorsSlugs: undefined;
  searchAuditors: SearchAuditorsParams;
  searchProjects: SearchProjectsParams;
  createAuditor: CreateAuditorParams;
  createIntegrator: CreateIntegratorParams;
  publishAudit: CreateAuditParams;
  getUser: undefined;
  getUploadReportAuthorization: undefined;
  updateUserProfilePicture: FormData;
  updateIntegrator: UpdateIntegratorParams;
  updateAuditor: UpdateAuditorParams;
  getIntegratorPageData: undefined;
  getAuditorPageData: undefined;
  getAuditPageData: undefined;
  getProjectPageData: undefined;
  extractAuditInfo: ExtractAuditInfoParams;
}

export type ApiRoutesReturnTypes = {
  [K in keyof typeof apiRoutes]: ApiRoutesResponses[K];
};

export type ApiRoutesData = {
  [K in keyof typeof apiRoutes]: ApiRoutesParams[K];
};

type RequestApiReturnType<K extends keyof typeof apiRoutes> = Promise<
  | {
      data: ApiRoutesReturnTypes[K];
      status: number;
      isError: false;
    }
  | RequestApiError
>;

type RequestApiError = {
  status?: number;
  isError: true;
  error?: string;
  requestId?: string;
};

type ErrorResponseData = {
  message: string;
  extra: { requestId: string };
  validationErrors?: Record<string, string>;
};

class ResponseError extends Error {
  response: Response;
  data: ErrorResponseData;

  constructor(message: string, res: Response, data: ErrorResponseData) {
    super(message);
    this.response = res;
    this.data = data;
  }
}

function requestApiErrorHandler(error: unknown) {
  const errorReponse: RequestApiError = {
    isError: true
  };

  if (error instanceof ResponseError) {
    let errorMessage = error.message;

    if (isValidationErrorResponse(error.data)) {
      errorMessage = Object.values(error.data.validationErrors)[0];
    }

    errorReponse.error = errorMessage;
    errorReponse.status = error.response.status;
    errorReponse.requestId = error.data?.extra?.requestId;
  }

  return errorReponse;
}

export async function requestApi<K extends keyof typeof apiRoutes>({
  route,
  data,
  headers,
  signal,
  cache = 'no-store'
}: {
  route: ReturnType<(typeof apiRoutes)[K]>;
  data?: ApiRoutesData[K];
  headers?: HeadersInit;
  signal?: AbortSignal;
  cache?: RequestCache;
}): RequestApiReturnType<K> {
  const [method, url, isMaster] = route;
  if (isMaster) {
    headers = {
      ...(headers || {}),
      Authorization: `Bearer ${environment.MASTER_KEY}`
    };
  }

  let searchParams = '';
  if (method === HttpMethod.Get && data) {
    searchParams = `?${Object.entries(data)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}=${value.join(',')}`;
        }
        if (value) return `${key}=${value}`;
        return null;
      })
      .filter(Boolean)
      .join('&')}`;
  }
  try {
    let body: BodyInit | null | undefined;
    if (data instanceof FormData) {
      body = data;
    } else if (method !== HttpMethod.Get) {
      body = JSON.stringify(data);
    }
    const response = await fetch(`${environment.API_URL}/${url}${searchParams}`, {
      method,
      headers,
      body,
      signal,
      cache
    });

    if (!response.ok) {
      const responseData = await response.json().catch(() => null);
      throw new ResponseError(responseData.message ?? 'Invalid fetch response', response, responseData);
    }

    const responseData = (await response.json()) as ApiRoutesReturnTypes[K];

    return {
      data: responseData,
      status: response.status,
      isError: false
    };
  } catch (error) {
    return requestApiErrorHandler(error);
  }
}
