import type { SecretKey } from '@trustblock/api/utils/services/secretManager';

export enum PlainKey {
  UPSTASH_REDIS_REST_URL = 'UPSTASH_REDIS_REST_URL',
  POSTHOG_HOST = 'POSTHOG_HOST',
  PRIVY_APP_ID = 'PRIVY_APP_ID'
}

enum PipelineKey {
  UPLOADS_BUCKET_URL = 'UPLOADS_BUCKET_URL',
  API_URL = 'API_URL',
  WEB_URL = 'WEB_URL',
  WIDGET_URL = 'WIDGET_URL'
}

enum ServerlessKey {
  UPDATE_METRICS_STATE_MACHINE_ARN = 'UPDATE_METRICS_STATE_MACHINE_ARN',
  PINATA_BASE_FOLDER = 'PINATA_BASE_FOLDER',
  UPLOAD_BUCKET_NAME = 'UPLOAD_BUCKET_NAME',
  REGION = 'REGION',
  STAGE = 'STAGE'
}

export type EnvironmentKey = SecretKey | PlainKey | PipelineKey | ServerlessKey;

// for more details: https://www.notion.so/trustblock/Env-Secret-Management-95e2d54f5fd842b0bd7175d9367a297b?pvs=4
const environment = {
  // -- BEGIN SECRETS --
  // PUBLIC vercel env
  POSTHOG_API_KEY: process.env.POSTHOG_API_KEY ?? process.env.NEXT_PUBLIC_POSTHOG_API_KEY, // vercel

  // PRIVATE vercel env
  MASTER_KEY: process.env.MASTER_KEY, // vercel
  // -- END SECRETS --

  // PLAIN
  PRIVY_APP_ID: process.env.PRIVY_APP_ID ?? process.env.NEXT_PUBLIC_PRIVY_APP_ID,
  UPSTASH_REDIS_REST_URL: process.env.UPSTASH_REDIS_REST_URL ?? process.env.NEXT_PUBLIC_UPSTASH_REDIS_REST_URL,
  POSTHOG_HOST: process.env.POSTHOG_HOST ?? process.env.NEXT_PUBLIC_POSTHOG_HOST,

  // PIPELINE
  // FROM GITHUB ACTIONS OR VERCEL
  UPLOADS_BUCKET_URL: process.env.UPLOADS_BUCKET_URL,
  API_URL: process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL, // vercel
  WEB_URL: process.env.WEB_URL ?? process.env.NEXT_PUBLIC_WEB_URL, // vercel
  WIDGET_URL: process.env.WIDGET_URL ?? process.env.NEXT_PUBLIC_WIDGET_URL, // vercel
  // FROM SERVERLESS
  UPDATE_METRICS_STATE_MACHINE_ARN: process.env.UPDATE_METRICS_STATE_MACHINE_ARN,
  PINATA_BASE_FOLDER: process.env.PINATA_BASE_FOLDER,
  UPLOAD_BUCKET_NAME: process.env.UPLOAD_BUCKET_NAME
} as Record<EnvironmentKey, string>;

export default environment;
